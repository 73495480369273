import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"
import { format } from "date-fns"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Box } from "../components/CompoundComponents/Box"
import FormFirst from "../components/form"
import ShoppingItems from "../components/ShoppingItems"

import { ShoppingConsumer } from "../layouts/index"

const BookingPage = ({ data, intl }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи, Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган"
        description="Один из лучших санаториев Узбекистана - Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган, Ниҳол саломатлик сиҳатгоҳи"
      />
      <NavBar />
      <Section>
        <Columns>
          <Column is8>
            <div className="is-shadow mb-2">
              <Img
                fluid={
                  data.section.edges[1].node.featuredImage.childImageSharp.fluid
                }
                alt={data.section.edges[1].node.id}
              />
            </div>

            <Box white>
              <FormFirst />
            </Box>
          </Column>
          <Column is4>
            <article className="message is-warning is-shadow">
              <div className="message-body">
                <div className="content">
                  <h3 className="mb-0 is-uppercase is-size-6-mobile">
                    {intl.formatMessage({
                      id: `header.nav.item-5`,
                    })}{" "}
                  </h3>
                </div>
              </div>
            </article>
            <article className="message is-danger is-shadow">
              <div className="message-body">
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.title`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-1`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-2`,
                  })}
                </p>
              </div>
            </article>
            <ShoppingConsumer>
              {({ service, startDate, endDate, quantity, name, phone }) => (
                <>
                  {service.length > 0 ? <ShoppingItems /> : null}
                  {startDate ? (
                    <article className="message is-primary is-shadow">
                      <div className="message-header">
                        <p>
                          {intl.formatMessage({
                            id: `booking.arrival-date`,
                          })}
                        </p>
                      </div>
                      <div className="message-body">
                        {format(startDate, "MM/dd/yyyy")}
                      </div>
                    </article>
                  ) : null}
                  {endDate ? (
                    <article className="message is-primary is-shadow">
                      <div className="message-header">
                        <p>
                          {intl.formatMessage({
                            id: `booking.date-of-departure`,
                          })}
                        </p>
                      </div>
                      <div className="message-body">
                        {format(endDate, "MM/dd/yyyy")}
                      </div>
                    </article>
                  ) : null}
                  {quantity ? (
                    <article className="message is-primary is-shadow">
                      <div className="message-header">
                        <p>
                          {intl.formatMessage({
                            id: `booking.quantity`,
                          })}
                        </p>
                      </div>
                      <div className="message-body">{quantity}</div>
                    </article>
                  ) : null}
                  {name ? (
                    <article className="message is-primary is-shadow">
                      <div className="message-header">
                        <p>
                          {intl.formatMessage({
                            id: `booking.name`,
                          })}
                        </p>
                      </div>
                      <div className="message-body">{name}</div>
                    </article>
                  ) : null}
                  {phone ? (
                    <article className="message is-primary is-shadow">
                      <div className="message-header">
                        <p>
                          {intl.formatMessage({
                            id: `booking.phone`,
                          })}
                        </p>
                      </div>
                      <div className="message-body">{phone}</div>
                    </article>
                  ) : null}
                </>
              )}
            </ShoppingConsumer>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export const BookingPageQuery = graphql`
  query {
    section: allProductsYaml {
      edges {
        node {
          id
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(BookingPage)
