import React from "react"
import { injectIntl, useIntl } from "gatsby-plugin-intl"
import { Formik, Form, Field } from "formik"
import DatePicker, { registerLocale } from "react-datepicker"
import axios from "axios"
import classNames from "classnames"
import { format } from "date-fns"
import * as Yup from "yup"

import { ru } from "date-fns/locale"

import PhoneInputField from "./PhoneInputField"
import { ShoppingConsumer } from "../layouts/index"

import "react-datepicker/dist/react-datepicker.css"

registerLocale("ru", ru)

const FormFirst = () => {
  const intl = useIntl()

  const ContactSchema = Yup.object().shape({
    name: Yup.string()
      .max(
        50,
        intl.formatMessage({
          id: `contact.required-50`,
        })
      )
      .required(
        intl.formatMessage({
          id: `contact.required`,
        })
      ),
    phone: Yup.string().required(
      intl.formatMessage({
        id: `contact.required`,
      })
    ),
    quantity: Yup.string()
      .max(
        2,
        intl.formatMessage({
          id: `contact.required-2`,
        })
      )
      .required(
        intl.formatMessage({
          id: `contact.required`,
        })
      ),
  })
  return (
    <ShoppingConsumer>
      {({
        handleButton,
        handleService,
        service,
        handleStartDate,
        startDate,
        handleEndDate,
        endDate,
        handleQuantity,
        handleName,
        handlePhone,
      }) => (
        <Formik
          initialValues={{
            name: "",
            quantity: "",
            phone: "",
          }}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            const msg = encodeURIComponent(
              "<b>----------Ф.И.Ш----------\n\n</b>" +
                `${values.name}\n\n` +
                "<b>----------Телефон рақам----------\n\n</b>" +
                `${values.phone}\n\n` +
                "<b>----------Одам сони----------\n\n</b>" +
                `${values.quantity}\n\n` +
                "<b>----------Бориш даври----------\n\n</b>" +
                `${format(startDate, "MM/dd/yyyy")}\n\n` +
                "<b>----------Тугатиш даври----------\n\n</b>" +
                `${format(endDate, "MM/dd/yyyy")}\n\n` +
                "<b>----------Заказ----------\n\n</b>" +
                `${service.map(item => `${item.name}`)} \n\n`
            )
            const url = `https://api.telegram.org/bot1740012248:AAG824lZlZEtZWUFDK1N0PTufcY2b-QFIuw/sendMessage?chat_id=1399062871&parse_mode=HTML&text=${msg}`
            axios
              .get(url)
              .then(function(response) {
                actions.setStatus({ msg: "Сообщение успешно отправлено" })
                setTimeout(actions.resetForm, 3000)
              })
              .catch(function(error) {
                console.log(error)
              })

            handleButton()
          }}
        >
          {({ errors, touched, status, values, handleChange }) => {
            const handleInput = e => {
              console.log(e.target.value)
              handleQuantity(e.target.value)
              handleChange(e)
            }
            const handleNameInput = e => {
              console.log(e.target.value)
              handleName(e.target.value)
              handleChange(e)
            }

            return (
              <Form className="container has-family-pt-sans">
                <div className="field mb-1">
                  <div class="buttons">
                    {service.filter(e => e.id === "item-1").length >
                    0 ? null : (
                      <div
                        onClick={() => handleService("item-1")}
                        className="button is-primary is-outlined has-text-weight-bold"
                      >
                        {intl.formatMessage({
                          id: "header.dropdown_item_4.item-1",
                        })}
                      </div>
                    )}

                    {service.filter(e => e.id === "item-3").length >
                    0 ? null : (
                      <div
                        onClick={() => handleService("item-3")}
                        className="button is-primary is-outlined has-text-weight-bold"
                      >
                        {intl.formatMessage({
                          id: "header.dropdown_item_4.item-3",
                        })}
                      </div>
                    )}
                    {service.filter(e => e.id === "item-4").length >
                    0 ? null : (
                      <div
                        onClick={() => handleService("item-4")}
                        className="button is-primary is-outlined has-text-weight-bold"
                      >
                        {intl.formatMessage({
                          id: "header.dropdown_item_4.item-4",
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="field mb-1">
                  <div className="control">
                    <label className={classNames("label is-size-7-mobile")}>
                      {intl.formatMessage({
                        id: `booking.arrival-date`,
                      })}
                    </label>
                    <DatePicker
                      selected={startDate}
                      className="input is-medium has-family-pt-sans is-size-6-mobile"
                      onChange={date => handleStartDate(date)}
                      locale="ru"
                    />
                  </div>
                </div>
                <div className="field mb-1">
                  <div className="control">
                    <label className={classNames("label is-size-7-mobile")}>
                      {intl.formatMessage({
                        id: `booking.date-of-departure`,
                      })}
                    </label>
                    <DatePicker
                      selected={endDate}
                      className="input is-medium has-family-pt-sans is-size-6-mobile"
                      onChange={date => handleEndDate(date)}
                      locale="ru"
                    />
                  </div>
                </div>
                <div className="field mb-1">
                  <div className="control">
                    <label className={classNames("label is-size-7-mobile")}>
                      {intl.formatMessage({
                        id: `booking.quantity`,
                      })}
                    </label>
                    <Field
                      name="quantity"
                      onChange={handleInput}
                      id="quantity"
                      type="number"
                      className={classNames(
                        "input is-medium has-family-pt-sans is-size-6-mobile"
                      )}
                    />
                    {errors.quantity && touched.quantity ? (
                      <p className="is-size-7 has-text-primary has-text-weight-semibold">
                        {errors.quantity}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="field mb-1">
                  <div className="control">
                    <label className={classNames("label is-size-7-mobile")}>
                      {intl.formatMessage({
                        id: `booking.name`,
                      })}
                    </label>
                    <Field
                      name="name"
                      onChange={handleNameInput}
                      id="name"
                      className={classNames(
                        "input is-medium has-family-pt-sans is-size-6-mobile"
                      )}
                    />
                    {errors.name && touched.name ? (
                      <p className="is-size-7 has-text-primary has-text-weight-semibold">
                        {errors.name}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="field mb-1">
                  <div className="control">
                    <label className={classNames("label is-size-7-mobile")}>
                      {intl.formatMessage({
                        id: `booking.phone`,
                      })}
                    </label>
                    <Field
                      className={classNames(
                        "input is-medium has-family-pt-sans"
                      )}
                      name="phone"
                      component={PhoneInputField}
                    />
                    {errors.phone && touched.phone ? (
                      <p className="is-size-7 has-text-primary has-text-weight-semibold">
                        {errors.phone}
                      </p>
                    ) : null}
                  </div>
                </div>

                {status && status.msg && (
                  <p className="has-text-weight-medium has-text-primary mb-1 has-text-centered">
                    {intl.formatMessage({
                      id: `contact.success`,
                    })}
                  </p>
                )}
                {values.name &&
                  values.phone &&
                  values.quantity &&
                  startDate &&
                  endDate &&
                  service.length > 0 && (
                    <button
                      type="submit"
                      className="button hvr-bounce-to-right is-size-6-mobile has-text-weight-bold is-normal is-primary is-outlined mb-2 is-uppercase"
                    >
                      {intl.formatMessage({
                        id: `button.send`,
                      })}
                    </button>
                  )}
              </Form>
            )
          }}
        </Formik>
      )}
    </ShoppingConsumer>
  )
}

export default injectIntl(FormFirst)
