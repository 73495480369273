import React from "react"
import PhoneInput from "react-phone-input-2"
import { ShoppingConsumer } from "../layouts/index"
import "react-phone-input-2/lib/style.css"

const PhoneInputField = ({ field, form }) => {
  return (
    <div>
      <ShoppingConsumer>
        {({ handlePhone }) => {
          const handlePhoneInput = e => {
            handlePhone(e)
          }
          return (
            <PhoneInput
              inputClass="input is-size-6"
              inputProps={{
                name: "phone",
                required: true,
              }}
              country={"uz"}
              placeholder="xxx xx xxx-xx-xx"
              name={field.name}
              value={field.value}
              onChange={value => {
                handlePhoneInput(value)
                if (!form.touched[field.name]) form.setFieldTouched(field.name)
                form.setFieldValue(field.name, value)
              }}
            />
          )
        }}
      </ShoppingConsumer>
    </div>
  )
}

export default PhoneInputField
